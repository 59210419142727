import React from "react";
import axios from "axios";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { BsClock } from "react-icons/bs";
//redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../redux/actions/authAction";
import { BASE_URL } from "../config";
const api = axios.create({
  baseURL: `${BASE_URL}/version`,
});
const NavBar = () => {
  const [port, setPort] = React.useState(null);
  const [version, setVersion] = React.useState(null);
  const cancelToken = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  //redux
  const profileRedux = useSelector((state) => state.authReducer.profile);
  const getProfile = () => {
    const profileValue = JSON.parse(localStorage.getItem("profile"));
    if (profileValue) {
      dispatch(updateProfile(profileValue));
      gerVersion();
    }
  };
  const gerVersion = async () => {
    const resp = await api.get("/", {
      cancelToken: cancelToken.current.token,
    });
    const { VERSION, PORT } = resp.data;
    setPort(PORT);
    setVersion(VERSION);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    history.replace("/");
    dispatch(updateProfile(null));
  };
  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark">
        <NavLink className="navbar-brand" to="/" exact>
          <BsClock /> RB
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="/" exact className="nav-link" activeClassName="active">
              Home
            </NavLink>
            <NavLink to="/coupon" className="nav-link" activeClassName="active">
              Coupon
            </NavLink>
            <NavLink
              to="/datesetting"
              className="nav-link"
              activeClassName="active"
            >
              Date Setting
            </NavLink>
            <NavLink
              to="/transaction"
              className="nav-link"
              activeClassName="active"
            >
              Transaction
            </NavLink>
            <NavLink to="/chart" className="nav-link" activeClassName="active">
              Chart
            </NavLink>
            <NavDropdown title="Campaign" id="collasible-nav-dropdown">
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/campaign");
                }}
              >
                Campaign Management
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/department");
                }}
              >
                Department
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            {profileRedux ? (
              <>
                <NavDropdown
                  title={profileRedux.name}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item>version : {version || ""}</NavDropdown.Item>
                  <NavDropdown.Item>port : {port || ""}</NavDropdown.Item>
                </NavDropdown>
                <button className="btn btn-danger ml-2" onClick={logout}>
                  Log out
                </button>
              </>
            ) : (
              <>
                <NavLink
                  to="/login"
                  className="nav-link"
                  activeClassName="active"
                >
                  Login
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
